import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import {Link} from '../../components/Link'
import ClientOnly from '../../components/ClientOnly';
// import loadable from '@loadable/component'


// React.lazy(() => import('@dotlottie/player-component'))

// const Lottie = loadable(() => import("react-lottie-player"))

const ActionItem = (props) => {
  const {item} = props
  const [lottieData, setLottieData] = useState(null)
  useEffect(() => {
    const fetchJSON = async (url) => {
      const req = new Request(`https:${url}`)
      const data = await fetch(req)
      const json = await data.json()
      // console.log('json', item.label)
      setLottieData(json)
    }

    if (item && item.mode && !lottieData) {
      fetchJSON(item.lottieJson.file.url)
    }
  }, [item, lottieData])

  return (
    <Grid item xl={2} lg={3} md={4} sm={6} xs={12}>
      <Hidden smUp>
      <Grid
        container
        direction="column"
        alignItems="center"
        wrap="wrap"
        justify="center"
        style={{padding: 10, color: item.color, backgroundColor: item.backgroundColor, borderRadius: 15 }}
      >
        <Grid item xs={12} style={{minHeight: 64, minWidth: 64}}>
          <Link to={item.url} >

          {/* {lottieData && 
          <Lottie
            autoPlay
            play
            animationData={lottieData}
            style={{ height: 64, width: 64 }}
          />} */}
          <img src={item.icon.file.url} style={{minWidth: 64, minHeight: 64}} height="64" width="64" />
          {/* {!lottieData && <img src={item.icon.file.url} style={{minWidth: 64, minHeight: 64}} height="64" width="64" />} */}
          </Link>

        </Grid>
        <Grid item xs={12}>
          <Link to={item.url} style={{color: item.color}}>

          <Grid container direction="column" wrap="nowrap">
            <Grid item key="title">
             <Typography align="center" component="h2" variant="h5"><strong>{item.label}</strong></Typography>
            </Grid>
            <Grid item key="summary">
              <Typography align="center" component="p" variant="body1"><strong>{item.summary.summary}</strong></Typography>

            </Grid>
          </Grid>
          </Link>

        </Grid>
      </Grid>

      </Hidden>
      <Hidden only="xs">
      <Grid
        container
        direction="row"
        alignItems="center"
        wrap="wrap"
        justify="center"
        style={{minHeight: 100, color: item.color, backgroundColor: item.backgroundColor, borderRadius: 15 }}
      >
        <Grid item xs={12} sm={2} md={3} lg={3} xl={3} style={{ paddingLeft: 4 }}>
          <Link to={item.url}>
            {/* {lottieData && 
            <Lottie
              play
              autoPlay
              animationData={lottieData}
              style={{ height: 64, width: 64 }}
            />
            } */}
            {/* {!lottieData && <img src={item.icon.file.url} style={{maxWidth: 64}} />} */}
            <img src={item.icon.file.url} style={{maxWidth: 64}} />
          </Link>

        </Grid>
        <Grid item xs={12} sm={10} md={9} lg={9} xl={9}>
        <Link to={item.url} style={{color: item.color}}>

          <Grid container direction="column" wrap="nowrap" style={{ paddingLeft: 10 }}>
            <Grid item key="title">
             <Typography component="h2" variant="h5"><strong>{item.label}</strong></Typography>
            </Grid>
            <Grid item key="summary">
              <Typography component="p" variant="body1"><strong>{item.summary.summary}</strong></Typography>

            </Grid>
          </Grid>
          </Link>

        </Grid>
      </Grid>

      </Hidden>
    </Grid>

  )
}


export default (props) => {
  const data = props.data[props.componentId];
  return (
    <div style={{overflowX: 'hidden'}}>

    <ClientOnly withLoading={false}>

    <Grid container alignItems="center" justify="center">
      {data.items.map((item) => item.url && <ActionItem key={item.label} item={item} {...props} />)}
    </Grid>
    </ClientOnly>
    </div>
  )
}