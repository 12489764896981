import React from 'react';
import { graphql } from 'gatsby'

import GamesLayout from '../../components/GamesLayout';
import useMatchedQuery from '../../hooks/useMatchedQuery';

import ContentfulComponentSeo from '../../ContentfulComponents/ContentfulComponentSeo';

import ContentfulComponentHero from '../../ContentfulComponents/ContentfulComponentHero';

import ContentfulComponentActionControl from '../../ContentfulComponents/ContentfulComponentActionControl';


export const query = graphql`
query {
 

    contentfulComponentSeo0: contentfulComponentSeo (node_locale: {eq: "es-AR"}, contentful_id: {eq: "6XOVapv8BiKRjq7wYlJIG0"}) {
      
  title
  type
  description {
    description
  }
  image {
    id
    title
    gatsbyImageData(
      layout: FULL_WIDTH,
      placeholder: BLURRED,
    )
  }

      internal {
        type
      }    
    }
  





    contentfulComponentHero1: contentfulComponentHero (node_locale: {eq: "es-AR"}, contentful_id: {eq: "1DxUx1OKPIkNOV3tI7VZrS"}) {
      
id
title
contentful_id
node_locale
codeHero
gridModel
gridPosition
portraitGridModel
portraitGridPosition
textPosition
textColor
image {
  title
  gatsbyImageData(
    layout: FULL_WIDTH,
    placeholder: BLURRED,
  )
}
portraitImage {
  title
  gatsbyImageData(
    layout: FULL_WIDTH,
    placeholder: BLURRED,
  )
}
overlayText {
  raw
}

      internal {
        type
      }    
    }
  





    contentfulComponentActionControl2: contentfulComponentActionControl (node_locale: {eq: "es-AR"}, contentful_id: {eq: "4qS1GdVXJUKgNTUUu0yk00"}) {
      
name
node_locale
contentful_id
items {
  backgroundColor
  color
  label
  node_locale
  name
  mode
  summary {
    summary
  }
  url
  childContentfulPanelActionLottieConfigJsonNode {
    frames
    preview
    idle {
      direction
      enabled
      keepLastFrame
      loop
      loopTimes
      play
      playOnHover
      segmentFrom
      segmentTo
      segmentsEnabled
      speed
      start
    }
    load {
      direction
      enabled
      keepLastFrame
      loop
      loopTimes
      play
      segmentFrom
      playOnHover
      segmentTo
      segmentsEnabled
      speed
      start
    }
    hover {
      direction
      enabled
      keepLastFrame
      loop
      loopTimes
      play
      playOnHover
      segmentFrom
      segmentTo
      segmentsEnabled
      speed
      start
    }
    click {
      direction
      enabled
      keepLastFrame
      loop
      loopTimes
      play
      playOnHover
      segmentFrom
      segmentTo
      segmentsEnabled
      speed
      start
    }
  }
  icon {
    file {
      url
      fileName
      contentType
    }
    gatsbyImageData(
      layout: FULL_WIDTH,
      placeholder: BLURRED,
    )  
    title
  }
  lottieJson {
    file {
      url
    }
  }
  lottieConfig {
    id
  }
}
      internal {
        type
      }    
    }
  



 
 
 site {
  siteMetadata {
    siteUrl
  }
}}

`

const Page = (props) => {
  const seo = props.pageContext.seo;
  const matchedQuery = useMatchedQuery()
  const { contentIds, ...context } = props.pageContext
  const { data } = props;
  return (
    <GamesLayout {...context} matchedQuery={matchedQuery} useHeader={true} useContainer={false} withSearch={false} >
      
      <ContentfulComponentSeo {...props} matchedQuery={matchedQuery} useHeader={true} useContainer={false} componentId="contentfulComponentSeo0" pathName="/shop/home" />
      
      <ContentfulComponentHero {...props} matchedQuery={matchedQuery} useHeader={true} useContainer={false} componentId="contentfulComponentHero1" pathName="/shop/home" />
      
      <ContentfulComponentActionControl {...props} matchedQuery={matchedQuery} useHeader={true} useContainer={false} componentId="contentfulComponentActionControl2" pathName="/shop/home" />
      
    </GamesLayout>
  )
}

export default Page;
